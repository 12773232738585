<!-- Informes APDS -->

<template>
  <div class="apdsReportF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>
                

        <!-- Botonera -->
        <div class="pt-2 pl-2">         
          <baseBtraExtra class="conflex"           
            :permExtra="permExtra"
            :modulo="btExtCfg"           
            @onEvent="execAccion">             
          </baseBtraExtra>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:950px">       
          <div class="conflex">
            <div class="columna" style="width:32%">

              <!-- Menú -->  
              <div class="cab">Tipos de Informes</div>
              <v-sheet v-bind="$cfg.styles.marco">        
                <v-treeview
                  :items="recordsRead[0]"
                  item-key="d"
                  item-text="n"
                  item-children="c"
                  activatable
                  dense
                  open-on-click
                  return-object
                  @update:active="changeInf">            
                </v-treeview>
              </v-sheet>
            </div>

            <div class="columna" style="width:66%;margin-left:10px">
              <div class="cab">Condiciones</div>

              <v-sheet v-bind="$cfg.styles.marco"> 

                <div style="display:flex">
                    <v-select
                        v-bind="$select"
                        v-model="ct.ord[2]"
                        :label="ct.ord[1]"
                        :items="ord"
                        item-value="d"
                        item-text="n">
                    </v-select>
                </div>

                <div style="display:flex">  
                    <uiText                    
                        :label="ct.txt[1]"
                        v-model="ct.txt[2]">                    
                    </uiText>
                    <uiText                    
                        :label="ct.sit[1]"
                        v-model="ct.sit[2]">                    
                    </uiText>                    
                </div>  

                <div style="display:flex">
                    <v-select                  
                        v-bind="$select"
                        v-model="ct.tip[2]"
                        :label="ct.tip[1]"
                        :items="itemsTipos"
                        item-value="id"
                        item-text="name">            
                    </v-select>   
                     <v-select                  
                        v-bind="$select"
                        v-model="ct.std[2]"
                        :label="ct.std[1]"
                        :items="recordsRead[2]"
                        item-value="d"
                        item-text="n">            
                    </v-select> 
                </div>

                <div style="display:flex">

                    <ctrlF style="flex: 0 0 50%" :ct="ct.cen"></ctrlF>

                    <v-select
                        style="flex: 0 0 50%"
                        v-bind="$select"
                        v-model="ct.grupo[2]"
                        :label="ct.grupo[1]"            
                        :items="recordsRead[3]"
                        item-value="d"
                        item-text="n"          
                        multiple>                              
                        <template v-slot:selection="{ index }">              
                            <span  
                            v-if="index=== 0"              
                            class="grey--text caption">                
                                ({{ ct.grupo[2].length }} grupo/s seleccionado/s)
                            </span>
                        </template>
                    </v-select>         
                    
                </div>

                <div style="display:flex">                              
                    <ctrlF style="flex: 0 0 100%" :ct="ct.ori"></ctrlF>                    
                </div>

                <div style="display:flex">  
                    <v-select   
                        style="flex: 0 0 60%"               
                        v-bind="$select"
                        v-model="ct.zona[2]"
                        :label="ct.zona[1]"
                        :items="itemsZonas"
                        item-value="id"
                        item-text="name"
                        :disabled="zona>0">
                    </v-select>   
                    <uiText                    
                        :label="ct.ref[1]"
                        v-model="ct.ref[2]">                    
                    </uiText>  
                </div>
             
              </v-sheet>
            </div> 
          </div>          
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>
  
  import { mixR } from "@/mixins/mixR.js";
  import baseHeader from "@/base/baseHeader";  
  import baseBtraExtra from "@/base/baseBtraExtra";  
  
  export default {
    mixins: [mixR],
    components: { baseHeader, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "funesR",
          titulo:"Informe Funerarias Sucursales", 
          name:"funesR",
          mView:'',
          pView:[]
        },        
       
        ord: [],
        itemsTipos:[],
        itemsZonas:[],

      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.btExtCfg.btnsAccion= [
          { accion:'verPdf', btn:"pdf" },
          { accion:'verExcel', btn:"excel" }

        ]; 

        //Tipos Empresa
        this.itemsTipos= this.$store.state.datos_iniciales.tipo_empresa.slice();        
        this.itemsTipos.unshift({id:'0',name:'( Selecciona Tipo )'});        
        // coordinadores de servicio
        this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();        
        this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});
       
      },


      // muestro informe en pdf
      verPdf() {       
        this.showReport("pdf");       
      },

      // muestro informe en excel
      verExcel() {        
        this.showReport("xls");
      },


      // muestro informe
      async showReport(tip) {
        // compruebo si hay seleccionado un Informe
        if (!this.ct.inf[2]) {
          this.$root.$alert.open('Debes seleccionar un informe', 'error');
          return;
        }
        
        this.report(tip);
      }

    }
  };
</script>
